import styled from 'styled-components'

export const HDivider = styled.hr`
  width: 100%;
  margin: 16px auto;
  height: 1px;
  background-color: ${({ theme }) => theme.color.border.base.neutral.soft};
  color: transparent;
  border: none;
`

export const VDivider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 8px;
  background-color: ${({ theme }) => theme.gray300};
  border: none;
`

export const HPrimaryDivider = styled(HDivider)`
  height: 2px;
`
