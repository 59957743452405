import styled from 'styled-components'
import { type FooterData } from '@/features/cms/components/CMS/types'
import { FooterContent } from '@/features/shared/components/Footers/Footer/FooterContent'
import { FooterSections } from '@/features/shared/components/Footers/Footer/FooterSections'
import { ReferenceRenderer } from '@/features/cms/components/CMS/ReferenceRenderer'
import { CMSErrorHandler } from '@/features/cms/components/CMS/CMSErrorHandler'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

/**
 * This component corresponds to the `footer` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/footer
 * - **Production** - https://cms.shipt.com/content-type/pool/footer
 */
export const CMSFooter = ({ content_type_id, id, data }: FooterData) => {
  try {
    const contentType = data?.content_type ?? 'default'
    return (
      <FooterWrapper {...getCMSWrapperProps({ content_type_id, id })}>
        <FooterContent
          sections={<FooterSections contentType={contentType} />}
          contentType={contentType}
        >
          <ReferenceRenderer references={data?.references} />
        </FooterContent>
      </FooterWrapper>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const FooterWrapper = styled.footer`
  width: 100%;
  position: relative;
  margin-bottom: -1.5rem;
`
