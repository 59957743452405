import styled from 'styled-components'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { Row, Column, Stack } from '@shipt/design-system-layouts'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { routes } from '@shared/constants/routes'
import { getFooterIcon } from '@/features/shared/components/Footers/Footer/utils'
import {
  footerData,
  shopperPrivacy,
} from '@/features/shared/components/Footers/MarketingFooter/constants'
import { type FooterContentType } from '@/features/shared/components/Footers/MarketingFooter/types'
import { ShiptStaticData } from '@/features/shared/constants/shiptStaticData'
import { trackElementClicked } from '@/features/shared/analytics/element'
import { AppBadges } from '@/features/shared/components/AppBadges'
import { Body, Link } from '@shipt/design-system-typography'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'
import { useHydrated } from '@/features/shared/components/ClientOnly'
import { VDivider } from '@/features/shared/components/Dividers'

type Props = {
  sections: JSX.Element
  children?: React.ReactNode
  contentType?: FooterContentType
}

export const FooterContent = ({
  sections,
  children,
  contentType = 'default',
}: Props) => {
  const images = ShiptStaticData.images
  const { socialLinks = [], privacy = [] } = footerData
  const hydrated = useHydrated()

  return (
    <FooterContentWrapper>
      <Column paddingBottom="4xl">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          marginTop={{ base: '4xl', md: '5xl' }}
          spacing={{ base: 'lg', md: 'xl' }}
        >
          <a href={routes.UNGATED_HOMEPAGE.url}>
            <Image
              alt="home"
              src={images.footer_logo}
              width={100}
              height={36}
            />
          </a>
          {sections}
        </Stack>

        <Column spacing="xl" marginBottom="xxl">
          <Row spacing="xl">
            {socialLinks.map(({ name, url }) => (
              <a
                aria-label={name}
                target="_blank"
                key={name}
                href={url}
                rel="noreferrer"
                onClick={() => {
                  trackElementClicked({ link_name: name, next_location: url })
                }}
              >
                {getFooterIcon(name)}
              </a>
            ))}
          </Row>
          <AppBadges
            openInNewTab
            isShopper={contentType === 'shopper'}
            displaySublocation="footer"
          />
          <div>
            <Image
              src={objectStorageUrl('newsweek2024.webp')}
              width={302}
              height={112}
              alt=""
            />
          </div>
        </Column>
        <Body size="sm">
          &copy; {hydrated ? new Date().getFullYear() : ''} Shipt, Inc. and its
          services are not necessarily affiliated or endorsed by the retailers
          on this site. Shipt does not operate in Alaska, Wyoming, and West
          Virginia.
        </Body>
        {children}
        <Row wrap marginTop="lg" spacing="sm">
          {(contentType === 'default' ? privacy : shopperPrivacy).map(
            (link, index) => (
              <Row key={link.name} align="center">
                {index !== 0 && <Line />}
                {'icon' in link && <link.icon />}
                <Link size="md" href={link.url}>
                  {link.name}
                </Link>
              </Row>
            )
          )}
        </Row>
      </Column>
    </FooterContentWrapper>
  )
}

const FooterContentWrapper = styled(GridLayoutContainer)`
  background-color: ${({ theme }) => theme.oatmeal};
`

const Line = styled(VDivider)`
  height: 1rem;
`
