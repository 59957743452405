import { Accordion, AccordionGroup } from '@shipt/design-system-accordion'
import { type FooterSection } from '@/features/shared/components/Footers/MarketingFooter/types'
import { MarketingFooterLinks } from '@/features/shared/components/Footers/MarketingFooter/MarketingFooterLinks'
import { Box, Column } from '@shipt/design-system-layouts'
import { Body } from '@shipt/design-system-typography'

export const MobileFooterAccordion = ({
  sections,
}: {
  sections: FooterSection[]
}) => {
  return (
    <Box display={{ base: 'block', md: 'none' }} marginBottom="3xl">
      <AccordionGroup hasLeadingBorder={false}>
        {sections.map(({ title, links, subSections }) => (
          <Accordion key={title} title={title} headerAs="h3">
            <Column spacing="xl">
              <MarketingFooterLinks links={links} />
            </Column>
            {subSections?.map(({ title: subTitle, links: subLinks }) => (
              <Column spacing="xl" key={subTitle} marginTop="xl">
                <Body strong>{subTitle}</Body>
                <Column spacing="xl">
                  <MarketingFooterLinks links={subLinks} />
                </Column>
              </Column>
            ))}
          </Accordion>
        ))}
      </AccordionGroup>
    </Box>
  )
}
